import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
  useTheme,
  UnorderedList,
  ListItem,
  OrderedList,
  Link,
  Card,
  CardHeader,
  CardBody,
  SimpleGrid,
  Stack,
  useMediaQuery,
} from "@chakra-ui/react";

const Content = ({ content = {}, contact = {} }) => {
  const { colors } = useTheme();
  console.log(contact, "contacts");
  const location = useLocation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");

  // Automatically scroll to the "contactus" section if it's in the URL
  useEffect(() => {
    if (location.hash === "#contactus" || location.hash === "#joinin") {
      const contactElement = document.getElementById("contactus");
      if (contactElement) {
        contactElement.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <Box
      textAlign="left"
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
      justifyContent={"center"}
    >
      <Box
        height="auto"
        width={{ base: "90%", md: "80%", lg: "80%" }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        {/* Title */}
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content?.title || "About us"}
        </Heading>

        {/* Description */}
        {content?.description?.map((cont, index) => (
          <Text
            key={index}
            textAlign="left"
            fontSize="md"
            color="gray.700"
            mb={10}
          >
            {cont.descr}
          </Text>
        ))}
        <SimpleGrid
          columns={isMobileView ? 1 : 2}
          spacing={6}
          width={"100%"}
        >
          {isMobileView
            ? [content.ourTeam[0], content.ourTeam[2], content.ourTeam[1], content.ourTeam[3]].map((team, index) => (
              team ? ( // Ensure the team exists
                <Card
                  key={index}
                  direction={{ base: "column", sm: "row" }}
                  overflow="hidden"
                  variant="outline"
                  bgGradient="linear(to-t, white, secondary.100)"
                  mt={3}
                >
                  <Stack>
                    <CardBody>
                      <Box>
                        <Heading size="sm" color={"primary.300"}>
                          {team.name}
                        </Heading>
                        <Text fontWeight={"500"} mb={5}>
                          {team.title}
                        </Text>
                        <Image
                          objectFit="contain"
                          src={team.image}
                          alt="team"
                          width={100}
                          height={150}
                          p={2}
                          ml={4}
                        />
                      </Box>
                      <Text>{team.descr}</Text>
                    </CardBody>
                  </Stack>
                </Card>
              ) : null
            ))
            : content.ourTeam?.map((team, index) => (
              <Card
                key={index}
                direction={{ base: "column", sm: "row" }}
                overflow="hidden"
                variant="outline"
                bgGradient="linear(to-t, white, secondary.100)"
                mt={3}
              >
                <Stack>
                  <CardBody>
                    <Box>
                      <Heading size="sm" color={"primary.300"}>
                        {team.name}
                      </Heading>
                      <Text fontWeight={"500"} mb={5}>
                        {team.title}
                      </Text>
                      <Image
                        objectFit="contain"
                        src={team.image}
                        alt="team"
                        width={100}
                        height={150}
                        p={2}
                        ml={4}
                      />
                    </Box>
                    <Text>{team.descr}</Text>
                  </CardBody>
                </Stack>
              </Card>
            ))}
        </SimpleGrid>


        {/* Join Section */}
        {content.join.map((jo, joinIndex) => (
          <Box key={joinIndex} mt={10}>
            <Heading
              size="md"
              mb={5}
              pl={0}
              pr={4}
              pt={2}
              pb={2}
              color={colors.primary[300]}
              borderRadius={"xl"}
              width={"fit-content"}
            >
              {jo.title}
            </Heading>
            <Text
              ml={jo.title !== "" ? 4 : 0}
              textAlign="left"
              fontSize="md"
              color="gray.700"
              mb={4}
            >
              {jo.joinDescription}
            </Text>

            {jo.instructions.map((instructr, instructrIndex) => (
              <Box key={instructrIndex}>
                <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
                  {instructr.descr}
                </Text>
                {instructr?.instructr?.map((instruct, instructIndex) => (
                  <UnorderedList key={instructIndex}>
                    <ListItem ml={4}>{instruct}</ListItem>
                  </UnorderedList>
                ))}
              </Box>
            ))}
          </Box>
        ))}

        {/* Get Involved */}
        {content.getInvolved.map((involve, involveIndex) => (
          <Box key={involveIndex} >
            <Text mt={5} textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {involve.title}
            </Text>
            {involve.instructions.map((instruction, instructionIndex) => (
              <Text ml={4} fontWeight={'bold'}
              >{instruction}</Text>
            ))}

            {involve.how.map((h, hIndex) => (
              <Box key={hIndex}>
                <Heading
                  size="md"
                  mb={4}
                  p={2}
                  pl={4}
                  pr={4}
                  pt={2}
                  pb={2}
                  color={colors.primary[300]}
                  borderRadius={"xl"}
                  bg={
                    hIndex === 0
                      ? colors.primary[100]
                      : hIndex === 1
                        ? colors.secondary[100]
                        : hIndex === 2
                          ? colors.accent[100]
                          : colors.green[100]
                  }
                  width={"fit-content"}
                  mt={10}
                >
                  {hIndex + 1}. {h.title}
                </Heading>
                {h.descr.map((desc, descIndex) => (
                  <Box key={descIndex}>
                    <Text
                      textAlign="left"
                      fontSize="md"
                      color="gray.700"
                      mb={4}
                      ml={4}
                    >
                      {desc.descr}: <Link href={`${desc.url}`}>{desc.url}</Link>
                      <b>{desc.bold}</b>
                    </Text>
                    {h.moreInfo.map((more, moreIndex) => (
                      <Box key={moreIndex}>
                        <Text
                          textAlign="left"
                          fontSize="md"
                          color="gray.700"
                          mb={4}
                          ml={4}
                        >
                          {more.descr}{" "}
                          <Link
                            textDecoration={"underline"}
                            color="primary.300"
                            href={
                              more?.link.includes("@")
                                ? "mailto:" + `${more.link}`
                                : `${more.link}`
                            }
                          >
                            {moreIndex === 0 && descIndex === 0
                              ? ""
                              : more.link}{" "}
                            {more.link !== "" ? <b>{more.bold}</b> : null}
                          </Link>
                          {more.link === "" ? <b>{more.bold}</b> : null}
                        </Text>

                        {more.image !== "" && (
                          <Image
                            src={`${more.image}`}
                            alt="slide"
                            width="20%"
                            objectFit="cover"
                            borderRadius="md"
                            mb={10}
                            ml={4}
                          />
                        )}
                      </Box>
                    ))}
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        ))}

        {/* Contact Us */}
        <Heading size="lg" mb={4} textStyle="Title" mt={20}>
          {contact?.title || "Contact Us"}
        </Heading>

        {contact?.contactUs?.map((cont, contIndex) => (
          <Box key={contIndex} id="contactus">
            <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {cont.question}
            </Text>
            <Text textAlign="left" fontSize="md" color="gray.700" mb={4}>
              {cont.description}
            </Text>
            <Link
              color={"primary.300"}
              fontWeight={"bold"}
              href={`mailto:${cont.contact}`}
            >
              {cont.contact}
            </Link>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
