import React from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  useTheme,
  useBreakpointValue,
  Heading
} from "@chakra-ui/react";
import { SubTitle } from "../uiComponents/Text";

const Content = ({ content }) => {
  const { colors } = useTheme();
  return (
    <Box
      textAlign="left"
      position="relative"
      width="100%"
      pt={5}
      backgroundColor={"transparent"}
    >
      <Box
        height="auto"
        width={{ base: '90%', md: '80%', lg: '80%' }}
        backgroundColor={"transparent"}
        justifyContent={"center"}
        margin={"0 auto"}
        pt={5}
      >
        <Heading size="lg" mb={4} bg={colors.primary[200]} textStyle="Title">
          {content.title}
        </Heading>

        <SubTitle color={colors.primary[300]} mb={5}>
          {content.question}
        </SubTitle>

        {content.description.map((cont, index) => (
          <Text
            key={index}
            textAlign="left"
            fontSize="md"
            color="gray.700"
            mb={4}
          >
            {cont.descr}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default Content;
