import React from 'react';
import { Box, Heading, Image, Text, VStack, useMediaQuery } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import LanguageSwitch from './LanguageSwitch';

const HeroSection = () => {
  const { t } = useTranslation();
  const [isMobileView] = useMediaQuery("(max-width: 991px)");
  console.log(isMobileView, 'is. obile')
  return (
    <Box
      id="heroSection" 
      textAlign="center"
      objectFit="cover"
      position="relative"
      bgImage={`url(${t('home.banner_images', {returnObjects: true})[0]})`}
      bgSize="cover"
      bgPosition="center"
      width="100%"
      height={isMobileView ? "40vh": "55vh"}
      display="flex"
    // flexDirection="row" 
    // justifyContent="space-between"
    >
      <VStack
        flexDirection="row"
        display={isMobileView? "inline-block":"flex"}
        width={{ base: "95%", md: "80%", lg: "80%" }}
        alignItems={'flex-start'}
        justifyContent={'center'}
      >
        <Box width={isMobileView ? "100%":"50%"}>
          <Image
            src={t('home.banner_images',  { returnObjects: true })[1]}
            alt="child"
            position="absolute"
            width={{ base: 150, md: 200, lg: 400 }}
            bottom="0"
            left={20}
          />

        </Box>
        <Box
          // width="100%" 
          width={isMobileView ? "100%":"100%"}
          color="white"
          mt={isMobileView ? "13%":"10%"}
          justifyContent="flex-start"
          zIndex={1}
          ml={isMobileView?0:"-10%"}
        >
          <Text color="white" fontSize={isMobileView ? "sm" : "xl"} fontWeight="600">
            {t('home.welcomeMessage')}
          </Text>
          <Text fontSize={isMobileView ? "md" : "2xl"} color="white" fontWeight="bold">
            {t('home.welcomeMessageDescription')}
          </Text>
        </Box>
      </VStack>
      {!isMobileView &&
      <VStack  pr={10} pt={10} width={isMobileView ? "100%": '25%'} alignItems={'flex-end'}>
         <LanguageSwitch />
      </VStack>}

      <Image
          src={t('home.banner_images',  { returnObjects: true })[2]}
          alt="partners"
          position={'absolute'}
          width={{ base: 120, md: 200, lg: 200 }}
          bottom={isMobileView ? 2: 10}
          right={10}
        />
    </Box>
  );
};

export default HeroSection;
